import {
  IWixAPI,
  ILocation,
} from '@wix/native-components-infra/dist/src/types/types';

import { ISearchLocation, ILocationSearchRequest } from './location.types';
import { decodeParams, decodeParamsFromPath, encodeParams } from './params';
import { convertProductFacetsRequestParamsToFilter } from '../../components/SearchResults/Widget/platform/products/facets';
import { DEFAULT_SEARCH_REQUEST } from './params/defaultSearchRequest';
import appConfig from '../../../.application.json';

const SEARCH_RESULTS_PAGE_ID = 'search_results';

export const toLocationSearchRequest: ISearchLocation['toLocationSearchRequest'] = (
  searchRequest,
) => {
  const { documentType, query, paging } = searchRequest;
  const {
    minPrice,
    maxPrice,
    collections,
  } = convertProductFacetsRequestParamsToFilter(searchRequest);

  return {
    documentType,
    query,
    page: paging.page,
    minPrice,
    maxPrice,
    collections,
  };
};

export const mergeUrl = (baseUrl: string, params: string): string => {
  return params === '' || params.startsWith('?')
    ? `${baseUrl}${params}`
    : `${baseUrl}/${params}`;
};

export const buildSearchResultsUrl = (
  searchResultsAbsoluteUrl: string,
  locationSearchRequest: ILocationSearchRequest,
  useOnlyQueryParams: boolean,
) => {
  const params = encodeParams(
    locationSearchRequest,
    {} as ILocation,
    useOnlyQueryParams,
  );
  return mergeUrl(searchResultsAbsoluteUrl, params);
};

export const createSearchLocation = (
  wixCodeApi: IWixAPI,
  useOnlyQueryParams: boolean,
): ISearchLocation => {
  function getSectionUrl() {
    return wixCodeApi.site.getSectionUrl({
      appDefinitionId: appConfig.appDefinitionId,
      sectionId: SEARCH_RESULTS_PAGE_ID,
    });
  }

  const searchLocation: ISearchLocation = {
    decodeParams() {
      return decodeParams(wixCodeApi.location);
    },

    decodeParamsFromPath() {
      return decodeParamsFromPath(wixCodeApi.location);
    },

    encodeParams(locationSearchRequest) {
      return encodeParams(
        locationSearchRequest,
        wixCodeApi.location,
        useOnlyQueryParams,
      );
    },

    async getSearchResultsRelativeUrl() {
      const { relativeUrl } = await getSectionUrl();
      if (typeof relativeUrl !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return relativeUrl;
    },

    async getSearchResultsAbsoluteUrl() {
      const { url } = await getSectionUrl();
      if (typeof url !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return url;
    },

    async navigateToSearchResults(locationSearchRequest, disableScrollToTop) {
      const relativeUrl = await searchLocation.getSearchResultsRelativeUrl();
      const params = searchLocation.encodeParams(locationSearchRequest);
      const url = mergeUrl(relativeUrl, params);

      wixCodeApi.location.to?.(url, { disableScrollToTop });
    },

    toLocationSearchRequest,

    toSDKSearchRequest({ page, documentType, query }, pageSize) {
      return {
        documentType,
        query: query || DEFAULT_SEARCH_REQUEST.query,
        paging: {
          page: page || DEFAULT_SEARCH_REQUEST.page,
          pageSize,
        },
      };
    },

    buildSearchResultsUrl(searchResultsAbsoluteUrl, locationSearchRequest) {
      return buildSearchResultsUrl(
        searchResultsAbsoluteUrl,
        locationSearchRequest,
        useOnlyQueryParams,
      );
    },
  };

  return searchLocation;
};
