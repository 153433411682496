import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../Widget/Widget.types';
import { ProductFacetsCollections } from './ProductFacetsCollections';
import { ProductFacetsPrice } from './ProductFacetsPrice';

import { st, classes } from './ProductFacets.st.css';

interface ProductFacetsProps {
  facets: IWidgetProps['productFacets'];
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
  locale: string;
  currency: string;
}

export const ProductFacets: FC<ProductFacetsProps> = ({
  facets,
  onFacetsFilterChange,
  locale,
  currency,
}) => {
  const { isMobile } = useEnvironment();
  const { collections, filter, minPrice, maxPrice } = facets;

  const showCollectionFacets =
    !!collections.length || !!filter.collections?.length;

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      {showCollectionFacets && (
        <>
          <ProductFacetsCollections
            collections={collections}
            selectedCollections={filter.collections}
            onFacetsFilterChange={onFacetsFilterChange}
          />
          <Divider className={classes.divider} />
        </>
      )}
      <ProductFacetsPrice
        locale={locale}
        currency={currency}
        minPrice={minPrice}
        maxPrice={maxPrice}
        selectedMinPrice={filter.minPrice}
        selectedMaxPrice={filter.maxPrice}
        onFacetsFilterChange={onFacetsFilterChange}
      />
    </div>
  );
};
