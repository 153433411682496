import { ILocation } from '@wix/native-components-infra/dist/src/types/types';

import {
  ILocationSearchRequest,
  IEncodedSearchPathParams,
  SearchQueryParams,
  SearchPathParams,
} from './location.types';
import { DEFAULT_SEARCH_REQUEST } from './params/defaultSearchRequest';
import {
  encodeSearchRequestToPathParams,
  encodeSearchRequestToQueryParams,
} from './params/encodeSearchRequest';
import {
  decodeSearchRequestFromPathParams,
  decodeSearchRequestFromQueryParams,
} from './params/decodeSearchRequest';

const PATH_PARAMS = Object.values(SearchPathParams);
const PATH_PARAM_VALUE_DELIMITER = '-';
const QUERY_PARAMS = Object.values(SearchQueryParams);

// Query params meant to be used together with path params (old legacy behaviour)
const LEGACY_QUERY_PARAMS_WITH_PATH = [
  SearchQueryParams.Collections,
  SearchQueryParams.Price,
];

export const decodeParams = (location: ILocation): ILocationSearchRequest => {
  return {
    ...DEFAULT_SEARCH_REQUEST,
    ...decodeSearchRequestFromQueryParams(location.query),
    ...decodeParamsFromPath(location),
  };
};

export const decodeParamsFromPath = (
  location: ILocation,
): ILocationSearchRequest => {
  // First item in location path is our search results page name
  const path = location.path.slice(1);

  const pathParams = path.reduce<IEncodedSearchPathParams>((result, param) => {
    const [key, ...value] = param.split(PATH_PARAM_VALUE_DELIMITER);

    return {
      ...result,
      [key]: value.join(PATH_PARAM_VALUE_DELIMITER),
    };
  }, {});

  return decodeSearchRequestFromPathParams(pathParams);
};

export const encodeParams = (
  request: ILocationSearchRequest,
  location: ILocation,
  useOnlyQueryParams: boolean,
): string => {
  const pathParams = !useOnlyQueryParams ? encodePathParams(request) : '';
  const queryParams = encodeQueryParams(request, location, useOnlyQueryParams);

  if (!pathParams && queryParams) {
    return `?${queryParams}`;
  } else if (pathParams && !queryParams) {
    return pathParams;
  }

  return [pathParams, queryParams].filter((param) => !!param).join('?');
};

const encodePathParams = (request: ILocationSearchRequest): string => {
  const params = encodeSearchRequestToPathParams(request);
  const paramsList = Object.keys(params) as SearchPathParams[];

  return paramsList
    .filter((param) => PATH_PARAMS.includes(param))
    .sort((a, b) => PATH_PARAMS.indexOf(a) - PATH_PARAMS.indexOf(b))
    .map((param) => `${param}${PATH_PARAM_VALUE_DELIMITER}${params[param]}`)
    .join('/');
};

const encodeQueryParams = (
  request: ILocationSearchRequest,
  location: ILocation,
  useOnlyQueryParams: boolean,
): string => {
  const params = encodeSearchRequestToQueryParams(request);
  const query = { ...location.query };

  const paramsToInclude = useOnlyQueryParams
    ? QUERY_PARAMS
    : LEGACY_QUERY_PARAMS_WITH_PATH;

  QUERY_PARAMS.forEach((param) => {
    delete query[param]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
  });

  paramsToInclude.forEach((param) => {
    const value = params[param];
    if (value !== undefined) {
      query[param] = value;
    }
  });

  const queryParams = new URLSearchParams(
    Object.entries(query).sort(([paramA], [paramB]) => {
      const isSearchQueryParamA = isSearchQueryParam(paramA);
      const isSearchQueryParamB = isSearchQueryParam(paramB);

      if (isSearchQueryParamA && isSearchQueryParamB) {
        return QUERY_PARAMS.indexOf(paramA) - QUERY_PARAMS.indexOf(paramB);
      } else if (isSearchQueryParamA && !isSearchQueryParamB) {
        return -1; // Our params always in front
      } else if (isSearchQueryParamB && !isSearchQueryParamA) {
        return 1; // Our params always in front
      } else {
        return 0;
      }
    }),
  );

  return queryParams.toString();
};

const isSearchQueryParam = (param: unknown): param is SearchQueryParams => {
  return typeof param === 'string'
    ? QUERY_PARAMS.includes(param as SearchQueryParams)
    : false;
};
