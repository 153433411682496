import * as React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { ResultsStatus } from '../ResultsStatus';
import { SortControl, ISearchSortProps } from '../SortControl';
import {
  getNoFacetsResultsFoundMessageKey,
  getNoFacetsResultsEmptyMessageKey,
  getFacetsResultsFoundMessageKey,
  getFacetsResultsEmptyMessageKey,
} from '../resultsMessage';
import settingsParams from '../../../settingsParams';

import { st, classes } from './StatusLine.st.css';

type StatusLineProps = {
  totalCount: number;
  resultsCount: number;
  facetsEnabled: boolean;
  searchQuery?: string;
  sortProps: ISearchSortProps;
};

const getResultsMessageKey = ({
  totalCount,
  resultsCount,
  facetsEnabled,
  settings,
}: {
  resultsCount: number;
  totalCount: number;
  facetsEnabled: boolean;
  settings: {
    isResultsMessageWithNumber: boolean;
    isResultsMessageWithQuery: boolean;
    isResultsEmptyMessageWithNumber: boolean;
    isResultsEmptyMessageWithQuery: boolean;
  };
}) => {
  if (resultsCount > 0) {
    if (facetsEnabled) {
      return getFacetsResultsFoundMessageKey(
        {
          isWithNumber: settings.isResultsMessageWithNumber,
          isWithQuery: settings.isResultsMessageWithQuery,
        },
        resultsCount === totalCount,
      );
    } else {
      return getNoFacetsResultsFoundMessageKey({
        isWithNumber: settings.isResultsMessageWithNumber,
        isWithQuery: settings.isResultsMessageWithQuery,
      });
    }
  } else {
    if (facetsEnabled) {
      return getFacetsResultsEmptyMessageKey({
        isWithNumber: settings.isResultsEmptyMessageWithNumber,
        isWithQuery: settings.isResultsEmptyMessageWithQuery,
      });
    } else {
      return getNoFacetsResultsEmptyMessageKey({
        isWithNumber: settings.isResultsEmptyMessageWithNumber,
        isWithQuery: settings.isResultsEmptyMessageWithQuery,
      });
    }
  }
};

export const StatusLine: React.FC<StatusLineProps> = ({
  facetsEnabled,
  resultsCount,
  totalCount,
  searchQuery,
  sortProps,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const settings = useSettings();

  const {
    isResultsEmptyMessageWithNumber,
    isResultsEmptyMessageWithQuery,
    isResultsMessageWithQuery,
    isResultsMessageWithNumber,
  } = settingsParams;

  const messageKey = getResultsMessageKey({
    settings: {
      isResultsEmptyMessageWithNumber: settings.get(
        isResultsEmptyMessageWithNumber,
      ),
      isResultsEmptyMessageWithQuery: settings.get(
        isResultsEmptyMessageWithQuery,
      ),
      isResultsMessageWithQuery: settings.get(isResultsMessageWithQuery),
      isResultsMessageWithNumber: settings.get(isResultsMessageWithNumber),
    },
    totalCount,
    resultsCount,
    facetsEnabled,
  });

  return (
    <div
      className={st(classes.root, { mobileView: isMobile, fluid: isEditorX })}
    >
      {!facetsEnabled ? (
        <ResultsStatus
          text={t(messageKey, {
            number: resultsCount,
            query: searchQuery || '',
          })}
        />
      ) : (
        <ResultsStatus
          text={t(messageKey, {
            number: resultsCount,
            query: searchQuery || '',
            totalCount,
          })}
        />
      )}
      {resultsCount > 0 && <SortControl {...sortProps} />}
    </div>
  );
};
