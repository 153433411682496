import {
  SearchDocumentType,
  ISearchRequestPageNumber,
  ISearchRequest,
} from '@wix/client-search-sdk';

// Old path params for backwards compatibility and to support redirects
export enum SearchPathParams {
  Query = 'q',
  DocumentType = 'qc',
  Page = 'page',
}

// NOTE: Order of enum values here will define query param ordering in the URL
export enum SearchQueryParams {
  Query = 'q',
  DocumentType = 'type',
  Page = 'page',
  Collections = 'collections',
  Price = 'price',
}

export interface ILocationSearchRequest {
  query?: string;
  documentType?: SearchDocumentType;
  page?: ISearchRequestPageNumber;
  minPrice?: number;
  maxPrice?: number;
  collections?: string[];
}

export type IEncodedSearchPathParams = {
  [key in SearchPathParams]?: string;
};

export type IEncodedSearchQueryParams = {
  [key in SearchQueryParams]?: string;
};

export interface ISearchLocation {
  decodeParams(): ILocationSearchRequest;
  decodeParamsFromPath(): ILocationSearchRequest;
  encodeParams(locationSearchRequest: ILocationSearchRequest): string;

  navigateToSearchResults(
    locationSearchRequest: ILocationSearchRequest,
    disableScrollTop?: boolean,
  ): Promise<void>;

  getSearchResultsRelativeUrl(): Promise<string>;
  getSearchResultsAbsoluteUrl(): Promise<string>;

  toSDKSearchRequest(
    locationSearchRequest: ILocationSearchRequest,
    pageSize: number,
  ): ISearchRequest;

  toLocationSearchRequest(
    searchRequest: ISearchRequest,
  ): ILocationSearchRequest;

  buildSearchResultsUrl(
    searchResultsAbsoluteUrl: string,
    locationSearchRequest: ILocationSearchRequest,
  ): string;
}
