import * as React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { IWidgetProps } from '../Widget/Widget.types';
import { st, classes } from './ProductFacetsEmptyMessage.st.css';

interface IProductFacetsEmptyMessageProps {
  onResetProductFacetsFilter: IWidgetProps['onResetProductFacetsFilter'];
}

export const ProductFacetsEmptyMessage: React.FC<IProductFacetsEmptyMessageProps> = ({
  onResetProductFacetsFilter,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      <p className={classes.message}>
        {t('searchResults.products.facets.noResultsFound.message')}
      </p>
      <p className={classes.resetContainer}>
        <button
          data-hook="no-results-reset-button"
          className={classes.resetButton}
          onClick={() => onResetProductFacetsFilter()}
        >
          {t('searchResults.products.facets.noResultsFound.resetButton.label')}
        </button>
      </p>
    </div>
  );
};
